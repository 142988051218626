<template>
	<div>
		<!-- 营养品设置 -->
		<div v-if="dialogFormVisible1 === false" class="wrap">
			<div class="child-top">
				<div>
					<el-select @change="getData" class="last-child" clearable placeholder="请选择分类" v-model="cascaderType">
						<el-option :key="item.uuid" :label="item.name" :value="item.uuid" v-for="item in options"> </el-option>
					</el-select>
					<div class="searchArea input-from please">
						<el-input class="input-with-select please" clearable placeholder="请输入关键字" style="width: 200px" suffix-icon="iconfont iconsousuo" v-model="keyword"></el-input>
						<el-button class="searchBtn" @click="getData(1)" round>搜索</el-button>
					</div>
				</div>
				<div class="operation-button">
					<el-button @click="addDataLog" class="new_btn" round :disabled="$store.getters.permissionsStr('新增')">
						<span>新增</span>
					</el-button>
					<el-button class="del_btn" @click="someDels" round :disabled="$store.getters.permissionsStr('删除') || !sels.length">
						<span>删除</span>
					</el-button>
				</div>
			</div>
			<el-table
				:empty-text="tableOne.tableData.length ? '' : '暂无数据'"
				style="100%"
				class="tableBox"
				:data="!$store.getters.permissionsStr('列表') ? tableOne.tableData : []"
				v-loading="tableLoading1"
				@selection-change="selsChange"
			>
				<el-table-column type="selection" align="center" width="55"></el-table-column>
				<el-table-column label="序号" width="60" type="index">
					<template slot-scope="scope">{{ (form.page - 1) * 10 + scope.$index + 1 }}</template>
				</el-table-column>
				<el-table-column label="图片" width="120" prop="img_path" show-overflow-tooltip>
					<template slot-scope="scope">
						<img :src="`${scope.row.img_host + scope.row.img_path}`" width="50" height="50" />
					</template>
				</el-table-column>
				<el-table-column label="营养品名称" min-width="100" prop="name" show-overflow-tooltip>
					<template slot-scope="scope">{{ scope.row.name }}</template>
				</el-table-column>
				<el-table-column label="一级分类" prop="article_cate_name" show-overflow-tooltip></el-table-column>
				<el-table-column label="创建人" prop="create_by" show-overflow-tooltip></el-table-column>
				<el-table-column label="创建时间" min-width="70" prop="create_time" show-overflow-tooltip></el-table-column>
				<el-table-column label="状态" prop="status" show-overflow-tooltip>
					<template slot-scope="scope">
						<el-switch
							v-show="scope.row.uuid"
							v-model="scope.row.status"
							active-color="#BDC6CF"
							inactive-color="#13ce66"
							:active-value="0"
							:inactive-value="1"
							style="width: 35px"
							@change="changeStatus1(scope.row)"
							:disabled="$store.getters.permissionsStr('启/停用')"
						></el-switch>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="140">
					<template slot-scope="scope">
						<el-button style="color: #2493e7" size="medium" type="text" @click="edit(scope.row)" v-show="scope.row.uuid" :disabled="$store.getters.permissionsStr('编辑')">编辑</el-button>
						<el-button style="color: #1a9f16" size="medium" type="text" @click="look(scope.row)" v-show="scope.row.uuid" :disabled="$store.getters.permissionsStr('查看')">查看</el-button>
						<el-button style="color: #f05756" size="medium" type="text" @click="delTableData(scope.row)" v-show="scope.row.uuid" :disabled="$store.getters.permissionsStr('删除')"
							>删除</el-button
						>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				:current-page.sync="form.page"
				:page-size="form.page_siaze"
				:total="form.total"
				@current-change="handleCurrentChange"
				class="table-pagination"
				layout="total, jumper,  ->, prev, pager, next"
			></el-pagination>
		</div>
	</div>
</template>
<script>
export default {
	name: 'NutritionSettings',
	data() {
		return {
			//列表数据
			form: {
				tableList: [],
				page: 1, //当前页
				page_size: 10,
				keyword: '', //搜索框
				total: 0,
				numType: '5',
			},
			img: '',
			tableImg: '',
			cascaderType: '',
			sels: [], // 列表选中列
			seachOPtions: [],
			seachListId: '',
			tabActive: true,
			options1: [],
			options: [], //
			disabledBtn: true, //禁用按钮
			selectRowsUuid: [], //选中行的uuid
			addformSet: {},
			roleData: {
				options: [],
				value: '',
				station: [],
				stationValue: '',
			},
			addform: {
				name: '',
				set_uuid: '',
				use_weight: '',
				use_weight_unit: '',
				use_number: '',
				use_number_unit: '',
				use_time: [],
				use_method: '',
				img_path: '',
				// 下面是非必填
				employ: '',
				manufacturer: '',
				batching: '',
				specifications: '',
				flavor: '',
				function: '',
				power: '',
				carbohydrate: '',
				protein: '',
				fat: '',
				sodium: '',
			},
			dialogView: false, // 查看数据弹窗状态
			tab: 'record', //标签页
			tableType: 1,
			tableOne: {
				tableData: [{ uuid: 0 }],
				sels: [], // 列表选中列
				total: 0, // 总数
				page: 1, // 当前分页
				limit: 10, // 每页条数
			},
			tableTwo: {
				tableData: [],
				sels: [], // 列表选中列
				total: 0, // 总数
				page: 1, // 当前分页
				limit: 10, // 每页条数
			},
			dialogFormVisible1: false,
			form2: {
				uuid: '',
				// name: ''
			},
			handleType2: 1,
			handleType1: 1,
			keywords2: '',
			keyword: '',
			tableLoading1: false,
			options: [],
			formsearch: {
				uuid: '',
				keywords: '',
			},
			fileList: [],
			info: {},
			title1: '',
			title2: '',
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.addformSet = { ...this.addform }
			// this.getlistAll();
			this.getData(1)
			// this.seachList();
		})
	},
	created() {
		var obj = JSON.parse(sessionStorage.getItem('arch_search'))
		if (obj) {
			this.cascaderType = obj.cascaderType
			this.keyword = obj.keyword
			this.form.page = obj.page
		}
		this.getData()
		this.getSelectData()
		// this.tableImg()
	},
	methods: {
		//新增
		addDataLog() {
			sessionStorage.setItem('arch_search', JSON.stringify({ cascaderType: this.cascaderType, keyword: this.keyword, page: this.form.page }))

			this.$router.push(`/nutritionSet/nutritionLibraryAdd?sh=show`)
		},
		edit(rows) {
			sessionStorage.setItem('arch_search', JSON.stringify({ cascaderType: this.cascaderType, keyword: this.keyword, page: this.form.page }))
			this.$router.push(`/nutritionSet/nutritionLibraryAddS?uuid=${rows.uuid}&&sh=show`)
		},
		look(rows) {
			sessionStorage.setItem('arch_search', JSON.stringify({ cascaderType: this.cascaderType, keyword: this.keyword, page: this.form.page }))
			this.$router.push(`/nutritionSet/nutritionLibraryAddS?uuid=${rows.uuid}&&sh=show&&name=look`)
		},

		//获取领域
		getSelectData() {
			this.$axios.post('p/articleCate/all', this.$qs({ type: 2 })).then(res => {
				if (res.data.code === 0) {
					this.options = res.data.data
					this.roleData.options = res.data.data
				} else {
				}
			})
		},
		changeTab() {
			this.tabActive = true
			this.$router.push('/NutritionManagement/NutritionSettings/nutritionList?time=' + this.date.join(','))
		},
		changeTab1() {
			this.tabActive = false
			this.$router.push("/NutritionManagement/NutritionSettings/nutritionLists?time='',''")
		},
		// 点击tab切换
		clickTabBtn(item) {
			this.$router.push({ path: item.link })
			this.tabBtn = item.name
		},

		selsChange(sels) {
			this.sels = sels
		},

		//多选删除
		someDels() {
			let that = this
			//删除数据
			if (that.sels.length == 0) {
				that.$message({
					message: '请选择要删除的数据',
					type: 'error',
					duration: 4000,
				})
				return
			}
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'success',
				closeOnClickModal: false,
				cancelButtonClass: '',
				center: true,
			}).then(() => {
				let params = []
				let url = '/p/nutrition/del'
				for (let i = 0; i < this.sels.length; i++) {
					params[i] = this.sels[i].uuid
				}
				let ids = params.join(',')
				let data = { uuids: ids }
				this.$axios.post(url, this.$qs(data)).then(res => {
					if (res.data.code == 0) {
						this.getData()
						this.$message({
							message: res.data.message,
							type: 'success',
						})
					} else {
						this.$message({
							message: res.data.message,
							type: 'error',
						})
					}
				})
			})
		},
		// 单行删除
		delTableData(row) {
			this.$confirm('确定删除数据吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				center: true,
			}).then(() => {
				this.$axios
					.post(
						'/p/nutrition/del',
						this.$qs({
							uuids: row.uuid,
						})
					)
					.then(res => {
						if (res.data.code === 0) {
							this.$message({
								message: res.data.message,
								type: 'success',
							})
							this.getData(this.currentPage)
						} else {
							this.$utils.message({
								type: 'error',
								message: res.data.message,
							})
						}
					})
					.catch(error => {})
			})
		},
		// toggleRowSelection(row) {
		//   if (row.length > 0) {
		//     this.disabledBtn = false;
		//     this.selectRowsUuid = row.map(value => {
		//       return value.uuid;
		//     });
		//   } else {
		//     this.disabledBtn = true;
		//   }
		// },
		//表中图片格式化
		// tableImg(row) {
		//   return this.$baseURL + "/" + row.img_path;
		// },
		//配置参数记录与配置参数查询的标签切换
		thisPageTab(value) {
			if (this.tableType == 2) {
				this.$router.push('/NutritionManagement/NutritionSettings/nutritionLists')
			} else if (this.tableType == 3) {
				this.$router.push('/NutritionManagement/NutritionSettings/timeList')
			}
		},
		/*  分页  */
		handleCurrentChange(val) {
			this.form.page = val
			this.getData()
		},
		getData(run_type) {
			if (run_type == 1) {
				this.tableOne.page = 1
			}
			let params = {
				keyword: this.keyword,
				page: this.page,
				page: this.form.page,
				page_size: this.form.page_size,
				cate_uuid: this.cascaderType,
				type: 2,
			}
			let url = '/p/nutrition/lists'
			this.tableLoading1 = true
			this.$axios
				.post(url, this.$qs(params))
				.then(response => {
					if (response.data.code == 0) {
						this.tableOne.tableData = response.data.data.rows
						this.form.total = response.data.data.total
						this.tableLoading1 = false
						for (let i in this.tableOne.tableData) {
							this.img = this.tableOne.tableData[i]
						}
					} else {
						this.$message({
							message: response.message,
							type: 'error',
						})
					}
					this.tableLoading1 = false
				})
				.catch(error => {
					this.tableLoading1 = false
					console.log(error)
				})
		},
		selsChange(sels) {
			this.sels = sels
		},
		view(data) {
			this.info = {}
			let url = '/p/nutrition/info'
			let params = {
				uuid: data.uuid,
			}
			this.$axios.post(url, this.$qs(params)).then(response => {
				if (response.code == 0) {
					this.info = response.data
				} else {
					this.$message({
						message: response.message,
						type: 'error',
					})
				}
			})
			this.dialogView = true
		},

		addForm2() {
			if (this.handleType2 === 1) {
				let url = '/p/nutrition/add'
				let params = {
					name: this.form2.name,
				}
				this.$refs.ruleForm1.validate(valid => {
					if (valid) {
						this.$axios.post(url, this.$qs(params)).then(response => {
							if (response.code === 0) {
								this.dialogFormVisible2 = false
								this.getData()
							} else {
								this.$message({
									message: response.message,
									type: 'error',
								})
							}
						})
					}
				})
			} else {
				let url = '/p/nutrition/update'
				let params = {
					name: this.form2.name,
					uuid: this.form2.uuid,
				}
				this.$axios.post(url, this.$qs(params)).then(response => {
					if (response.code === 0) {
						this.dialogFormVisible2 = false
						this.getData()
					} else {
						this.$message({
							message: response.message,
							type: 'error',
						})
					}
				})
			}
		},
		changeStatus1(data) {
			let params = {
				uuid: data.uuid,
				status: data.status,
			}
			//获取列表
			let url = '/p/nutrition/able'
			this.$axios
				.post(url, this.$qs(params))
				.then(response => {
					if (response.data.code == 0) {
						this.$message({
							message: '切换成功',
							type: 'success',
						})
					}
				})
				.catch(error => {
					console.log(error)
				})
		},
	},
}
</script>

<style lang="scss" scoped>
.search-box {
	display: flex;
	align-items: center;
}
.wrap {
	// padding-right: 10px;

	.topOperate {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 10px;

		.queryArea {
			margin-bottom: 0;

			.filterArea {
				/*margin: 10px 0;*/
				flex: 1;
				display: flex;
				align-items: center;

				.selectInput {
					width: 220px;
					display: flex;
				}
			}
		}
		.search-btn-area {
			display: flex;
			.search-btn {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.btn {
					display: flex;
					justify-content: flex-start;
				}

				.search-box {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
			}
		}
	}
}

body {
	padding: 0;
	margin: 0 auto;
}

ul {
	list-style: none;
}

#tab {
	width: 100%;
	height: 70px;
	background: #ffffff;
	box-shadow: 0px 2px 8px 0px #efefef;
}

#title li {
	width: 134px;
	float: left;
	line-height: 70px;
	text-align: center;
	color: #333333;
}

#title .select {
	width: 134px;
	height: 67px;
	border-bottom: 3px solid #2493e7;
}
#back {
	width: 110px;
	height: 38px;
	background: #ffffff;
	box-shadow: 0 2px 8px 0 #efefef;
	border-radius: 19px;
	margin-right: 30px;
	float: right;
	text-align: center;
	margin-top: 16px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 38px;
}
#content {
	padding: 41px;
	background: #ffffff;
	box-shadow: 2px 2px 8px 0px #efefef;
	border-radius: 2px;
}
#tit {
	width: 100%;
	height: 35px;
	font-size: 26px;
	font-family: MicrosoftYaHei;
	color: #333333;
	line-height: 35px;
	text-align: center;
	margin-top: 31px;
}
.buttonText[data-v-563acab6] {
	color: #373737;
}
.tips {
	width: 6px;
	height: 20px;
	background: #2493e7;
	border-radius: 3px;
	margin-left: 87px;
	margin-top: 31px;
}

.input {
	width: 350px;
	height: 48px;
	background: #ffffff;
	border-radius: 4px;
	border: 1px solid #e3e3e3;
	padding-left: 10px;
}

#fo li {
	width: 350px;
	float: left;
	margin-left: 120px;
	font-size: 16px;
	color: #666666;
	margin-top: 20px;
	margin-right: 70px;
}

.bt {
	color: #f73838;
	margin-right: 2px;
}

.sel {
	width: 120px;
	height: 48px;
	background: #ffffff;
	border-radius: 4px;
	border: 1px solid #e3e3e3;
}

#bot {
	padding: 41px;
	height: 200px;
	background: #ffffff;
	box-shadow: 2px 2px 8px 0px #efefef;
	border-radius: 2px;
}

.in {
	width: 90%;
	height: 48px;
	background: #ffffff;
	border-radius: 4px;
	border: 1px solid #e3e3e3;
	margin-right: 60px;
}

.ti {
	width: 119px;
	height: 21px;
	font-size: 16px;
	font-family: MicrosoftYaHei;
	color: #666666;
	line-height: 21px;
	text-align: inherit;
	margin-left: 60px;
}

#sub {
	width: 120px;
	height: 48px;
	background: #2493e7;
	border-radius: 2px;
	margin: 0 auto;
	text-align: center;
	margin-bottom: 1rem;
	margin-top: 1rem;
}

#sub span {
	width: 32px;
	height: 21px;
	font-size: 16px;
	font-family: MicrosoftYaHei;
	color: #ffffff;
	line-height: 48px;
}

.add-content {
	.add-content-title {
		height: 35px;
		font-size: 26px;
		font-family: MicrosoftYaHei;
		color: #333333;
		line-height: 35px;
		text-align: center;
	}

	.form-content {
		padding: 30px;

		.form-content-box {
			padding: 20px 0;

			.form-content-shortitle {
				font-size: 16px;
				font-weight: 600;
				margin-bottom: 10px;
				font-family: MicrosoftYaHei;
				height: 21px;
				display: flex;
				align-items: center;
				color: #333;

				&:before {
					content: '';
					width: 5px;
					height: 18px;
					border-radius: 4px;
					background: #2590e7;
					display: inline-block;
					margin-right: 7px;
				}

				span {
					padding-left: 10px;
					font-size: 14px;
					color: #999;
				}
			}

			.form-item-content {
				padding: 0 20px;

				.form-upload {
					.form-upload-defult {
						display: flex;
						align-items: center;

						.img {
							width: 80px;
							height: 80px;
							border-radius: 50%;
							display: flex;
							justify-content: center;
							align-items: center;
							color: #ffffff;
							background: rgba(0, 0, 0, 0.5);
						}

						.text {
							padding-left: 10px;

							p {
								text-align: left;
							}
						}
					}
				}

				.submitBtn {
					display: flex;
					justify-content: center;
				}
			}

			.divider {
				padding: 10px 0;
			}
		}
	}
}

.view-content {
	.view-info {
		padding-left: 30px;
		padding-bottom: 30px;
		display: flex;
		align-items: center;

		.avatar {
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			background: #dcdadd;
			overflow: hidden;

			img {
				width: 100%;
			}
		}

		.info-text {
			padding-left: 30px;

			.title {
				font-size: 22px;
				font-weight: 600;
				color: #333;
			}

			p {
				padding: 2px 0;
			}
		}
	}
	.view-card {
		margin-bottom: 20px;
		padding: 30px;
		box-shadow: 6px 6px 10px #ccc;

		.view-card-title {
			font-size: 18px;
			font-width: 600;
			height: 30px;
			display: flex;
			align-items: center;
			color: #333;

			&:before {
				content: '';
				width: 5px;
				height: 18px;
				border-radius: 4px;
				background: #2590e7;
				display: inline-block;
				margin-right: 7px;
			}

			span {
				padding-left: 10px;
				font-size: 14px;
				color: #999;
			}
		}

		.view-card-list {
			.view-card-list-item {
				padding: 10px 0;
				display: flex;
				border-bottom: 1px dashed #ccc;

				&:last-child {
					border-bottom: none;
				}
				.label {
					width: 150px;
					font-size: 18px;
					font-weight: 600;
				}

				.value {
					font-size: 18px;
				}
			}
		}
	}
}

.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}
.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}
.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: 178px;
	line-height: 178px;
	text-align: center;
}
.el-button.is-plain:hover {
	background: #f1f8ff;
}

.avatar {
	width: 178px;
	height: 178px;
	display: block;
}
.el-icon-picture-outline:before {
	content: '\E75E';
	line-height: 45px;
}

/deep/ .el-dialog__header {
	text-align: center;
}
/deep/ .dialog-footer {
	text-align: center;
}
.btn .blueColor {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}
.btn .el-button + .el-button {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}
.contentWrap .goBack {
	display: none;
}
/deep/ .el-table__body tbody td .cell {
	color: #333333;
	padding: 0;
}
/deep/.el-table table.el-table__header thead tr th .cell {
	font-weight: 500;
	color: #9d9d9d;
	padding: 0;
}
/deep/.el-input-group--append .el-input__inner,
.el-input-group__prepend {
	border-radius: 0;
}
/deep/ .tabArea .el-radio-button .el-radio-button__inner {
	padding-right: 0;
	padding-left: 0;
	width: 105px !important;
}
.search-box .sobtn {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	color: #fff;
	border-color: #409eff;
	background-color: #409eff;
	margin: -0.520833rem -1.141667rem;
	height: 40px;
}
/deep/ .el-input--suffix .el-input__inner {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	// height: 40px;
	// margin-left: 14.5px;
	// margin-top: -0.1rem;
	// width: 110px;
}
.child-top {
	.input-from {
		position: relative;
		.searchBtn {
			position: absolute;
			top: 0px;
			left: 13.5rem;
			// margin-top: 10px;
		}
	}
	.el-select {
		border-radius: 4px 0 0 4px;

		.el-input__inner {
			border-radius: 0 4px 4px 0;
		}
	}

	.left-child-input {
		margin-left: -1px;
		float: left;
		width: 150px;

		.el-input__inner {
			border-radius: 0 4px 4px 0;
		}
	}
	.please {
		margin-top: 10px;
		float: right !important;
		margin-left: 10px !important;
	}
	.last-child {
		float: left;
		padding-top: 20px;
		width: 210px;
		// margin-left: 30px !important;
	}
}
</style>
<style>
.el-dialog__wrapper .el-dialog {
	margin-top: 25vh !important;
}
.el-select-dropdown .el-popper {
	margin-right: 20px;
}
</style>
